import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderMockups = () => {
  let mockups = [];
  let items = [
    { class: "iphone light phone-small", img: "app/12.png", button: "notch", pclass: "text-center", title:"Professional Coaches", pdesc: "We ensure our coaches meet the standards to enable you to have the most memorable experience possible." },
    { class: "iphone light phone-small", img: "app/15.png", button: "notch", pclass: "text-center", title:"Face-to-Face Video", pdesc: "Video coaching calls enable you to build strong relationships with your coaches and feed your personal insights." },
    { class: "iphone light phone-small", img: "app/13.png", button: "notch", pclass: "text-center", title:"Instant Messaging", pdesc: "You have access to your Coach as and when you need them, through convenient and instant chat capabilities." },
    { class: "iphone light phone-small", img: "app/10.png", button: "notch", pclass: "text-center", title:"Personal Insights", pdesc: "We give you a robust set of personal insights that help you understand yourself and your progress." }
  ];

  items.forEach((mockup, i) => {
    mockups.push(
      <React.Fragment key={i}>
      <div className="gap-y align-items-centre mockup-holder mx-auto col-md-3">
        <div className={mockup.class}>
          <div className="screen">
            <img src={require(`assets/img/screens/${mockup.img}`)} alt="..." />
          </div>
          <div className={mockup.button} />
        </div>
        <div className="media-body mt-3 mt-md-0">
        <h5 className="bold text-center">{mockup.title}</h5>
          <p className={mockup.pclass}>
              {mockup.pdesc}
          </p>
        </div>
      </div>
    </React.Fragment>
    );
  });

  return mockups;
};

const forThePeople = () => {
  return <section className="section for-the-people">
    
      <div className="forthepeople-mockups container">
        <div className="col-md-12">
          <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "users"]}
                className="fa-2x text-danger mb-3"
              />
              <h2 className="heading-line">For The People</h2>
            </div>
        </div>
        {renderMockups()}
      </div>
    </section>;
};

export default forThePeople;
