import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "What does this product/solution offer to its customers?",
    answer:
      "We are here to give companies the ability to face imrpove their employee satisfaction over time. We offer the cabaility to provide Coaching-At-Scale which is backed up by professional, bespoke tooling and the insights tohelp measurable change happen at the subjective and objective level."
  },
  {
    question: "As a Coach how would I become a part of this community?",
    answer:
      "We hold the highest standrad for any Coach joining our community, starting with their process of training and being certified as a professional in their field. The other side of this is for us to get to know you as a person, what you are commited to and what gets you out of bed in the morning."
  },
  {
    question: "Why have you created this product?",
    answer:
      "We as founders are experinced in not only coaching but the personal development of the people we serve. One challenge we face into is the ability to create this amazing experience at scale to create an amplified impact for companies and the people within. This product will people lives a little better each day and by extention provide real value to companies."
  },
  {
    question: "How can we get access to this product?",
    answer:
      "We are currently in Beta stages of development and wokring closey with industry leaders to refine what we are creating. Register your interest via the site today and we will setup a conversation to understand how we can create a partnership together."
  }
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light top-left">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Frequently Asked Questions</h2>
          <p className="lead">Here are some powerful questions we get asked?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
