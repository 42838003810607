import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade, Zoom } from "react-reveal";

const FeaturesCantMiss = () => {
  return (
    <section className="section features-cant-miss">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom right>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom left delay={300}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-3">
          <Fade bottom right delay={400}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-4">
          <Fade bottom right delay={600}>
            <div />
          </Fade>
        </div>

        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>
      </div>
      <Container>
        <Row className="gap-y">
          <Col md={{ size: 6, order: "last" }}>
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "trophy"]}
                className="fa-2x text-danger mb-3"
              />
              <h2 className="heading-line">Digital Coaching Tools</h2>
            </div>

            <ul className="list-unstyled">
              <li className="media flex-column flex-md-row text-center text-md-left">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-way pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Consistent Focus Areas</h5>
                  <p className="m-0">
                    With our experience in the Coaching field, coupled with extensive research,
                    we have created a set of focus areas we believe people set and try to attain.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-add-user pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Self-Help</h5>
                  <p className="m-0">
                    This self-help area is focused on learning and growth. We provide it in a way that 
                    enables anyone to start that journey for themselves and then jump into the coaching 
                    support as and when they need it.
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-gift pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Coach Assigned</h5>
                  <p className="m-0">
                      During or after coaching sessions, the coaches can assign topics and exercises for people 
                      to explore. Enabling people to enhance and strengthen their journey towards their goals.
                  </p>
                </div>
              </li>
            </ul>
          </Col>

          <Col md="6">
            
            <figure className="bubble bubble-right rounded overflow-hidden shadow">
              <Fade right>
                <img
                  src={require("assets/img/screens/app/pieces/7.png")}
                  className="img-responsive"
                  alt=""
                />
              </Fade>
            </figure>

            <div className="bubble bubble-left center-x-md">
              <Zoom bottom>
                <figure className="rounded overflow-hidden shadow">
                  <img
                    src={require("assets/img/screens/app/pieces/6.png")}
                    className="img-responsive"
                    alt=""
                  />
                </figure>
              </Zoom>
            </div>

            <div className="iphone light">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/16.png")} alt="..." />
              </div>
              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesCantMiss;
