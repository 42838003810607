import React from "react";
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Heading = () => {
  return (
    <header className="header section alter3-header text-dark">
      {/* alter3-header for alternative page header */}
      <div className="shapes-container">
        <div className="animation-shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="animation-shape shape-circle animation--clockwise">
          <div />
        </div>
        <div className="animation-shape shape-triangle animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>

        <div className="static-shape shape-ring-1" />
        <div className="static-shape shape-ring-2" />
        <div className="static-shape shape-circle-1" />

        <div className="static-shape pattern-dots-1" />
        <div className="static-shape pattern-dots-2" />

        {/* <div className="static-shape ghost-shape ghost-shape-1" /> */}
      </div>

      <Container className="bring-to-front">
        <Row>
          <Col md="9">
            <h1 className="extra-bold display-md-4 font-md">
              The <a href="http://wthn.co/#" className="wthn-underlined">smart</a>coaching platform
              <span className="d-block display-md-4 light">
                for everybody
              </span>
            </h1>

            <Nav className="mt-5" tag="nav">

              <NavLink
                href="#!"
                className="btn-rounded btn-floating wthn-bg-blue py-3 wthn-btn wthn-btn-animate font-weight-bold"
              >
                Register For Early Access
              </NavLink>
            </Nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
