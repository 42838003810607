import React from "react";
import classNames from "classnames/bind";
import { Container, Row, Col } from "reactstrap";
// import MoreInfoButton from "components/MoreInfoButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tilt from "react-tilt";
import Fade from "react-reveal/Fade";

const renderItems = () => {
  let items = [];

  for (let i = 1; i < 5; i++) {
    items.push(
      <Tilt options={{ max: 20, glare: true, maxGlare: 0.4 }} key={i}>
        <div
          className={classNames(
            "rounded tilt overflow-hidden shadow-box shadow-hover bg-contrast",
            { "mt-5": i > 1 }
          )}
        >
          <figure>
            <img
              src={require(`assets/img/screens/app/pieces/insights/${i}.png`)}
              className="img-responsive"
              alt=""
            />
          </figure>
        </div>
      </Tilt>
    );
  }

  return items;
};

const MultipleDesignBlocks = () => {
  return (
    <section className="section powered-design company-insights">
      <div className="shapes-container">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom right duration={1500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom right duration={1200} delay={500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>

        <div className="shape pattern-dots-1" />
      </div>

      <Container>
        <Row className="gap-y align-items-center">

          <div className="col-md-12">
              <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "eye"]}
                className="fa-2x text-danger mb-3"
              />
              <h2 className="heading-line">Companies, gain valuable insight into <br />your People and Culture</h2>
            </div>
          </div>
          
          <Col md="5">
            {renderItems()}
          </Col>

          <Col md="7">
          <div className="company-insights-mockup container py-0">
            <div className="gap-y align-items-centre mockup-holder mx-auto">
              <div className="tablet ipad portrait">
                <div className="screen">
                  <img className="landscape" src={require(`assets/img/screens/tablet/2.png`)} alt="..." />
                  <img className="portrait" src={require(`assets/img/screens/tablet/4.png`)} alt="..." />
                </div>
                <div className="button" />
              </div>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MultipleDesignBlocks;
