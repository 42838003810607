import React from "react";
import { Container, Row, Col } from "reactstrap";
import MoreInfoButton from "components/MoreInfoButton.jsx";

const renderBubbles = () => {
  let bubbles = [];
  const items = [
    { icon: "smiling", name: "Smiling" },
    { icon: "happy", name: "Happy" },
    { icon: "smile", name: "Smile" },
    { icon: "calm", name: "Calm" },
    { icon: "love", name: "Love" },
    { icon: "excited", name: "Excited" },
    { icon: "kiss", name: "Kiss" },
    { icon: "smile", name: "Smile" },

    { icon: "love", name: "Love" },
    { icon: "happy", name: "Happy" },
    { icon: "smiling", name: "Smiling" },
    { icon: "kiss", name: "Kiss" },
    { icon: "excited", name: "Excited" },
    { icon: "calm", name: "Calm" },
    { icon: "happy", name: "Happy" },
    { icon: "smile", name: "Smile" }
  ];

  items.forEach((bubble, i) => {
    const iconSize =
      i % 4 === 0 ? "xl" : i % 3 === 0 ? "lg" : i % 2 === 0 ? "md" : "xxl";

    bubbles.push(
      <div
        key={i}
        className={`bubble bg-contrast rounded-circle p-2 shadow icon icon-${iconSize}`}
      >
        <img
          src={require(`assets/img/demo/header/${bubble.icon}.svg`)}
          alt=""
          className="img-responsive"
        />
        <span className="badge badge-contrast shadow-box">{bubble.name}</span>
      </div>
    );
  });

  return bubbles;
};

const Integration = () => {
  return (
    <section className="section integration-bubbles">
      <Container className="overflow-hidden">
        <Row>
          <Col md="6">
            <div className="bubbles-wrapper">
              <div className="animations m-0">{renderBubbles()}</div>
            </div>
          </Col>

          <Col md="6">
            <div className="section-heading">
              <p className="text-alternate text-uppercase bold">
                Making each day a little brighter
              </p>
              <h2 className="heading-line">Our Mission is to help people achieve the success they desire</h2>
              <p className="lead text-muted">
                The latest data across the globe shows that people are largely unhappy in their jobs. 
                We are here to help companies tap into that awareness of what people desire and provide powerful insights to take steps towards success. 
                Giving you the power to take targetted actions that are measurable.
              </p>
            </div>

            <MoreInfoButton text="Find out more" to="#!" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Integration;
