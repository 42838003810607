import React from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Subscribe = () => {
  return (
    <section className="section stay-tunned">
      <Container className="bring-to-front">
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">
          <div className="section-heading text-center">
            <h2 className="heading-line">Register for Early Access</h2>
            <p className="lead text-alternate">
                By registering with us, you will receive updates and the opportunity to get Early Access to this exciting product
            </p>
          </div>

          <div className="mx-auto position-relative form-wrapper">
            <form
              className="form text-center"
              data-response-message-animation="slide-in-left"
            >
              <div className="form-group input--group">
                <input
                  id="subscribe-email"
                  type="email"
                  name="email"
                  className="form-control form-control-lg text-center"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div class="form-group">
                <select class="form-control form-control-lg text-center" id="subscribe-customer-type" name="customer-type">
                  <option selected disabled>Who are you?</option>
                  <option>Company</option>
                  <option>Coach</option>
                  <option>Individual</option>
                </select>
              </div>

              <button type="submit" className="btn btn-lg btn-alternate">
                Register
              </button>
            </form>

            <div className="response-message">
              <FontAwesomeIcon icon={["fas", "envelope"]} className="fa-3x" />
              <p className="font-md m-0">Check your e-mail for the next steps...</p>
              <p className="response">
                We will share some details on what the next steps are in strengthening our partnership moving forward. 
                We look forward to what the future holds here.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
